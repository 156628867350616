$main-color-dark: #035eca;
$main-color-darker: #042143;
$main-color: #2687fb;
$main-color-light: #77b3fa;
$main-color-icon: #004599;
$accent-color: #ffc973;
$accent-color-gradient: #ff9c00;
$background: rgb(240, 245, 253);
$danger: #a94442;
$success: #3c763d;
$warning: #f0ad4e;
$text-less-important: rgb(176, 178, 190);
