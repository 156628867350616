@use '../abstracts/colors' as c;

.left-nav {
	margin-top: 1rem;
	display: flex;
	align-items: flex-start;
	min-width: 15rem;
	position: relative;
	z-index: 1;
}

.left-nav ul {
	list-style: none;
	margin-block-start: 0px;
	margin-block-end: 0px;
	padding-inline-start: 0px;
	width: 100%;
}
.left-nav > ul li {
	position: relative;
}
.left-nav > ul ul {
	display: none;
	position: absolute;
	background-color: c.$main-color-darker;
	top: 0px;
}
.left-nav li:hover > ul {
	display: block;
	animation: fade-in 500ms;
}
.left-nav > ul > li > ul {
	left: 100%;
}
.left-nav > ul > li > ul > li > ul {
	left: 100%;
}
.left-nav > ul > li > ul > li > ul > li > ul {
	left: 100%;
}
.left-nav > ul > li > ul > li > ul > li > ul > li > ul {
	left: 100%;
}
.left-nav-btn {
	font-size: 1rem;
	color: white;
	font-weight: bold;
	flex: 1;
	padding: 1rem;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	text-align: left;
	background-color: c.$main-color-darker;
}

.left-nav a {
	font-size: 1rem;
	color: white;
	font-weight: bold;
	flex: 1;
	padding: 1rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	text-align: left;
	text-decoration: none;
}
.left-nav a:hover {
	background-color: c.$main-color-dark;
}
@media (max-width: 769px) {
	.left-nav > ul ul {
		display: block;
	}
	.left-nav li {
		display: block;
	}
	.left-nav a {
		background-color: c.$main-color-darker;
	}
}
