@use '../abstracts/colors' as c;
@use '../abstracts/animations';

.home .wrapper {
	max-width: 1040px;
	margin: auto;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex: 1;
}
.home section.main {
	min-height: 100vh;
}
.home section.main .subsection .logo,
.home section.main .subsection .dl-btn,
.home section.main .subsection h1,
.home section.main .subsection p {
	position: relative;
	animation: appear-bottom 1.5s ease-in-out;
	animation-fill-mode: backwards;
}
.home section.main .subsection h1 {
	animation-delay: 0.2s;
}
.home section.main .subsection p {
	animation-delay: 0.4s;
	font-weight: bold;
	color: #707b8e;
	max-width: 20rem;
	margin: 1rem 0px;
	line-height: 1.4rem;
	text-align: justify;
}
.home section.main .subsection .dl-btn {
	animation-delay: 0.6s;
}
.home section.main .wrapper .subsection:last-child {
	animation: appear-right 1.5s ease-in-out;
}
.home section.main .circle {
	animation-delay: 0.2s;
	animation: appear-circle 1.5s ease-in-out;
}
.home h1 {
	font-size: 2.5rem;
	max-width: 20rem;
	text-align: justify;
}
.home h2 {
	margin-top: 2rem;
	text-align: center;
	color: var(--main-color-darker);
}
.home h3 {
	font-size: 1.1rem;
	margin-bottom: 0.2rem;
	color: var(--main-color-darker);
}

.home section:not(.ficheContent section) {
	position: relative;
	display: 'flex';
	flex: 1;
	padding: 5rem 2rem 0px;
}
.home section .screenshot {
	border: 1px solid rgba(47, 49, 82, 0.15);
	width: 100%;
	border-radius: 1rem;
}
.home section .anim {
	position: relative;
}
.home section .anim .screenshot:not(:first-child) {
	position: absolute;
	top: 0px;
	animation: fade-in-loop 28s ease-in-out;
	animation-fill-mode: backwards;
	animation-iteration-count: infinite;
}
.home section .anim .screenshot:nth-child(2) {
	animation-delay: 7s;
}
.home section .anim .screenshot:nth-child(3) {
	animation-delay: 14s;
}
.home section .anim .screenshot:nth-child(4) {
	animation-delay: 21s;
}

.home .subsection {
	display: 'flex';
	flex: 1;
	position: relative;
}
.home section .circle {
	position: absolute;
	z-index: -1;
	border-radius: 100rem;
	width: 80rem;
	height: 80rem;
	transition: width 1s, height 1s;
}
.home .circle.main {
	top: -24rem;
	left: 35rem;
	background-color: var(--main-color);
}
.home .circle.accent {
	top: -10rem;
	left: -50rem;
	background-color: var(--accent-color);
}

.home section .feature {
	padding: 1rem;
	display: flex;
	align-items: center;
}
.home section .feature h3 {
	font-size: 1.5rem;
}

.home section .feature .icon {
	width: 5rem;
	margin-right: 1rem;
	align-self: flex-start;
}

.home section .feature .react-icon {
	font-size: 5rem;
	color: var(--main-color);
	padding: 1rem 1rem 0px 0px;
	margin-right: 1rem;
	align-self: flex-start;
}
.home section .feature p {
	color: grey;
	text-align: justify;
	font-weight: bold;
}

.home .logo {
	width: 80px;
	height: 80px;
	margin-bottom: 1rem;
}

.separator {
	padding: 1rem;
	border-radius: 2rem;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}
.separator h3 {
	font-size: 1.5rem;
}
.separator img {
	margin-right: 2rem;
	width: 8rem;
	background-color: c.$main-color;
	padding: 1rem;
	border-radius: 2rem;
}

.moving-circles {
	background: linear-gradient(
		0deg,
		c.$main-color 0%,
		c.$main-color-dark 100%
	);
	animation: bubble-move linear infinite 12s;
	height: 150px;
	width: 150px;
	display: block;
	border-radius: 50rem;
}
.moving-circles.small {
	height: 75px;
	width: 75px;
}
.moving-circles.accent {
	background: linear-gradient(
		0deg,
		c.$accent-color 0%,
		c.$accent-color-gradient 100%
	);
}
@media (max-width: 1040px) {
	.main .subsection {
		flex: auto;
	}
}

@media (max-width: 769px) {
	.home section.main .wrapper {
		text-align: center;
	}
	.home .circle {
		display: none;
	}
	.home section:not(.ficheContent section) {
		padding: 5rem 0px 0px 0px !important;
	}

	.home section.main .subsection p,
	.home h1 {
		max-width: none;
	}

	.home section.main {
		min-height: auto;
	}
	.home section.main .wrapper {
		text-align: center;
	}
	.home section .recos {
		padding: 1rem;
		width: 100%;
	}
	.home section h3 {
		display: flex;
		align-items: center;
	}

	.fiches {
		flex-direction: column;
		flex-wrap: wrap;
		overflow-x: hidden;
	}

	.home section.main .subsection .logo,
	.home section.main .subsection .dl-btn,
	.home section.main .subsection h1,
	.home section.main .subsection p {
		animation: appear-left 1.5s ease-in-out;
		animation-fill-mode: backwards;
	}
	.home section.main .subsection h1 {
		animation-delay: 0.2s;
	}
	.home section.main .subsection p {
		animation-delay: 0.4s;
	}
	.home section.main .subsection .dl-btn {
		animation-delay: 0.6s;
	}
	.separator img {
		width: 6rem;
		height: 6rem;
	}
	.home section .feature p {
		margin-top: 1rem;
	}
}
