/** @format */

@use './components/header';
@use './components/footer';
@use './components/home';
@use './components/buttons';
@use './components/leftnav';
@use './components/pricing';
@use './components/logo';
@use './components/recos';
@use './components/fiches';
@use './components/phoneMockup';
@use './components/table';
@use './components/contact';
@use './components/compte';
@use './components/auth';
@import url(http://fonts.googleapis.com/css?family=Roboto:400,400italic,500,500italic,700,700italic,900,900italic,300italic,300,100italic,100);

* {
	margin: 0;
	padding: 0;
	border: none;
	background-color: transparent;
	-webkit-tap-highlight-color: rgba(255, 255, 255, 0) !important;
	-webkit-focus-ring-color: rgba(255, 255, 255, 0) !important;
	outline: none !important;
	-webkit-appearance: none;
	box-sizing: border-box;
}
*:focus {
	-webkit-tap-highlight-color: rgba(255, 255, 255, 0) !important;
	-webkit-focus-ring-color: rgba(255, 255, 255, 0) !important;
	outline: none !important;
}
input[type='checkbox'] {
	-webkit-appearance: checkbox;
}
input[type='radio'] {
	-webkit-appearance: radio;
}
body {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
html,
body,
#root {
	min-height: 100%;
	height: 100%;
}
#root {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	color: var(--main-color-darker);
}

ol,
ul {
	margin-block-start: 1em;
	margin-block-end: 1em;
	padding-inline-start: 2rem;
}

body {
	font-family: Roboto;
	--main-color-dark: #035eca;
	--main-color-darker: #042143;
	--main-color: #2687fb;
	--main-color-light: #77b3fa;
	--main-color-icon: #004599;
	--accent-color: #ffc973;
	--background: rgb(240, 245, 253);
	--danger: #a94442;
	--success: #3c763d;
	--warning: #f0ad4e;
	--less-important: rgb(176, 178, 190);
	width: 100vw;
	overflow-x: hidden;
	padding-top: 3.5rem;
	font-family: 'Roboto', sans-serif;
}

.wrapper {
	max-width: 1040px;
	margin: auto;
}

.invisible {
	visibility: hidden;
}

.arrow {
	font-size: 1rem;
}

@media (min-width: 770px) {
	.hidden-lg {
		display: none !important;
	}
}
@media (max-width: 769px) {
	.hidden-sm {
		display: none !important;
	}
	html {
		font-size: 14px;
	}
	section .wrapper {
		flex-direction: column;
		padding: 1rem;
	}
	#root {
		display: block;
	}
}
