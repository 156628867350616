@use '../abstracts/colors' as c;


.compte {
	padding: 3rem;
	width: 100%;
	box-sizing: border-box;
}

.compte .error{
	animation: shake 0.5s ease-in-out;
	color: white;
	background-color: c.$danger;
	padding: 0.5rem;
	border-radius: 0.5rem;
}

.compte .success{
	color: white;
	background-color: c.$success;
	padding: 0.5rem;
	border-radius: 0.5rem;
}

@media (max-width: 769px) {
	.compte {
		padding: 3rem 1rem;
	}
}
