/** @format */

@use '../abstracts/colors' as c;
button:hover {
	cursor: pointer;
}
.btn {
	transition: background-color 0.2s, color 0.2s, border-color 0.2s;
	border: 1px solid c.$main-color;
	font-size: 1rem;
	color: white;
	background-color: c.$main-color;
	font-weight: bold;
	margin: 1rem 0px;
	border-radius: 2rem;
	padding: 0.5rem 1rem;
}
.btn:hover {
	background-color: c.$main-color-light;
	border: 1px solid c.$main-color-light;
}
.btn.clear {
	background-color: transparent;
	color: c.$main-color;
}
.btn.clear:hover {
	color: white;
	background-color: c.$main-color;
}

.txt-btn {
	transition: color 0.2s;
	font-size: 1rem;
	padding: 1rem 1rem;
	font-weight: bold;
	color: c.$main-color-darker;
}

.txt-btn:hover,
.txt-btn.selected {
	color: c.$main-color;
}

.txt-btn:hover,
.txt-btn.selected {
	color: c.$main-color;
}

.dl-btn {
	color: white;
	transition: filter 0.2s;
	background-color: c.$main-color-darker;
	border-radius: 2rem;
	padding: 0.2rem;
	margin: 1rem;
}
.dl-btn:hover {
	filter: brightness(1.4);
}
.dl-btn img {
	width: 10rem;
}

.left-nav-btn:hover {
	background-color: c.$main-color-dark;
}
.left-nav-btn.disabled,
.left-nav-btn.disabled:hover {
	background-color: grey;
}
.btn.disabled,
.btn.disabled:hover {
	background-color: grey;
	border-color: grey;
}
