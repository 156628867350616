/** @format */

@use '../abstracts/colors' as c;
.app-table {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 1px;
	margin: 2rem;
}
.app-table > div {
	box-shadow: 0 0 0 1px #ddd;
}
.app-table .title,
.app-table .sub-title {
	padding: 0.5rem;
	border-bottom: 1px solid #ddd;
	background-color: #f9f9f9;
	font-weight: bold;
	text-align: center;
}
.app-table .indications>.sub-title {
	color: var(--success);
}

.app-table .contreIndications>.sub-title {
	color: var(--danger);
}
.app-table .precautions>.sub-title {
	color: var(--warning);
}

.app-table .indications>.title {
	background-color: var(--success);
	color: white;
}

.app-table .contreIndications>.title {
	background-color: var(--danger);
	color: white;
}
.app-table .precautions>.title {
	background-color: var(--warning);
	color: white;
}
.app-table > div:last-child:nth-child(odd) {
	grid-column: 1 / span 2;
}

/* .app-table div.text{
	padding: 0 1rem;
} */
.app-table div.text p, .app-table div.text ul{
	padding: 0.5rem 1rem;
}
.app-table .app-table{
	margin: 0;
}

@media (max-width: 1000px) {
	.app-table {
		display: inline-block;
		/* grid-auto-rows: 1fr;
		grid-template-columns: 1fr; */
	}
	/* .app-table > div:last-child:nth-child(odd) {
		grid-column: auto;
	} */
}
