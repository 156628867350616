/** @format */

@use '../abstracts/colors' as c;
header {
	z-index: 3;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	text-align: right;
	display: flex;
	background-color: white;
}
header.sticky {
	box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 6px 0px;
}

header .brand-name {
	font-weight: 900;
	color: c.$main-color;
	font-size: 2rem;
}
header .wrapper {
	max-width: 1040px;
	margin: auto;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex: 1;
}

header a {
	transition: color 0.2s;
	font-size: 1rem;
	padding: 1rem 1rem;
	font-weight: bold;
	color: c.$main-color-darker;
	text-decoration: none;
}

header a:hover,
header a.selected {
	color: c.$main-color;
}
header .signup a {
	color: c.$main-color;
	padding: 0.5rem 1rem;
	display: inline-block;
	transition: background-color 0.2s, color 0.2s, border-color 0.2s;
	border: 1px solid c.$main-color;
	font-size: 1rem;
	background-color: transparent;
	color: c.$main-color;
	font-weight: bold;
	margin: 1rem 0px;
	border-radius: 2rem;
	padding: 0.5rem 1rem;
}
header .signup:hover a {
	color: white;
	background-color: c.$main-color;
}
header .signup.active a {
	color: white;
}
header .signup.active a {
	background-color: c.$main-color;
}
header .authentification a {
	text-transform: uppercase;
	font-size: 0.75rem;
}

header .login a {
	color: grey;
	&:hover {
		color: c.$main-color;
	}
}
header a:hover,
header .active a {
	color: c.$main-color;
}
@media (max-width: 1040px) {
	header .wrapper {
		padding: 0px 1rem;
	}
}

@media (max-width: 769px) {
	header .wrapper {
		padding: 1rem;
	}
}
