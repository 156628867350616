/** @format */

@use '../abstracts/colors' as c;
footer {
	width: 100%;
	text-align: right;
	display: flex;
	align-items: center;
	background-color: c.$main-color-darker;
	padding: 1rem;
	padding-bottom: 2rem;
}
footer .wrapper {
	flex-direction: row;
	display: flex;
}
footer p {
	opacity: 0.8;
	color: white;
	font-weight: bold;
	margin: 1rem;
	text-align: center;
}
footer a {
	opacity: 0.8;
	text-decoration: none;
	color: white;
	font-weight: bold;
	margin: 0px 1rem;
	text-align: center;
}
footer a:hover {
	opacity: 1;
}
@media (max-width: 769px) {
	footer .wrapper {
		flex-direction: column;
	}
}
