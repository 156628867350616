/** @format */

@use '../abstracts/colors' as c;
.contact h1 {
	text-align: center;
	color: c.$main-color;
}
.contact h2 {
	font-size: 1rem;
	margin-left: 1rem;
	color: c.$main-color-darker;
}
.contact .input.invalid {
	animation: shake 0.5s ease-in-out;
	border: c.$danger;
}
.contact .confirmation-message {
	text-align: center;
	margin-top: 2rem;
	font-weight: bold;
}
.contact .confirmation-message.sent {
	color: c.$success;
}
.contact .confirmation-message.error {
	color: c.$danger;
}
.contact textarea {
	resize: none;
	height: 25rem;
	font-size: 1rem;
	width: 100%;
}
.contact .input-wrapper {
	display: flex;
}
.contact form {
	max-width: 50rem;
	padding: 2rem 3rem;
	margin: auto;
	margin-bottom: 3rem;
}
.contact .input-wrapper > div {
	width: 100%;
}
.contact form .input {
	background-color: white;
	border: 1px solid #d4d4d4;
	margin: 0.75rem;
	margin-bottom: 1.5rem;
	display: flex;
	font-size: 1.5rem;
	padding: 0.5rem;
	border-radius: 0.2rem;
	box-shadow: rgba(0, 0, 0, 0.12) 0px 0px 6px 0px;
}
.contact form .input .icon {
	display: inline-block;
	color: #b0b2be;
}
.contact form .input input {
	font-size: 1rem;
	margin-left: 1rem;
	width: 100%;
}
.contact .logo {
	align-self: center;
}
.contact form h1 {
	color: white;
	text-align: center;
	margin: 1rem 0px;
}
.contact .checkbox input {
	margin-right: 1rem;
}
.contact .checkbox {
	color: white;
	text-align: justify;
	margin: 0.5rem 0rem;
	display: flex;
	align-items: center;
	font-size: 1rem;
}
.contact a {
	color: white;
	font-weight: bold;
}
.contact a:hover {
	opacity: 0.8;
}

.contact {
	margin-top: 2rem;
}
.contact .input:focus-within {
	border-color: c.$main-color;
	box-shadow: rgba(0, 0, 0, 0.12) 0px 0px 6px 0px;
}
.contact form .input:focus-within .icon {
	color: c.$main-color;
}
.contact .btn {
	float: right;
	padding-left: 3rem;
	padding-right: 3rem;
}
@media (max-width: 769px) {
	.contact form {
		padding: 2rem 1rem;
	}
}
