@use '../abstracts/colors' as c;

.recos ul {
	list-style: none;
}
.recos .date {
	font-weight: bold;
	margin-right: 1rem;
	font-size: 1.2rem;
}
.recos li {
	margin-bottom: 0.5rem;
	display: flex;
	text-align: justify;
}
.recos a {
	color: var(--main-color-dark);
	font-weight: bold;
	font-size: 1.2rem;
}
.recos a:hover {
	color: var(--main-color-light);
}
.recos {
	padding: 3rem;
	width: 100%;
	box-sizing: border-box;
}
.recos h1 {
	color: var(--main-color);
	text-align: center;
	margin-bottom: 3rem;
	margin-top: 1rem;
}
.recos h2 {
	color: var(--main-color-darker);
	margin-top: 1.5rem;
	margin-bottom: 2rem;
	padding-top: 0.5rem;
}
.recos h2:not(:first-of-type) {
	border-top: 3px solid var(--main-color-light);
}
.recos h3 {
	color: var(--main-color-darker);
	margin-top: 0.5rem;
	margin-bottom: 1rem;
}
@media (max-width: 769px) {
	.recos {
		padding: 3rem 1rem;
	}
}
