/** @format */

@keyframes grow {
	0% {
		height: 0%;
		overflow: hidden;
	}
	99% {
		height: 99%;
		overflow: hidden;
	}
	100% {
		height: 100%;
	}
}
@keyframes fade-in-loop {
	0% {
		opacity: 0;
	}
	3% {
		opacity: 1;
	}
	27% {
		opacity: 1;
	}
	30% {
		opacity: 0;
	}
	100% {
		opacity: 0;
	}
}
@keyframes fade-in {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes appear-right {
	from {
		position: relative;
		left: 100vw;
	}
	to {
		position: relative;
		left: 0vw;
	}
}
@keyframes appear-left {
	from {
		position: relative;
		right: 100vw;
	}
	to {
		position: relative;
		right: 0vw;
	}
}
@keyframes appear-circle {
	from {
		position: absolute;
		left: 100vw;
	}
	to {
		position: absolute;
		left: 35rem;
	}
}
@keyframes appear-bottom {
	0% {
		position: relative;
		top: 100vh;
	}
	100% {
		position: relative;
		top: 0vh;
	}
}
@keyframes shake {
	0% {
		position: relative;
		left: -10px;
	}
	20% {
		position: relative;
		left: 10px;
	}
	40% {
		position: relative;
		left: -10px;
	}
	60% {
		position: relative;
		left: 10px;
	}
	80% {
		position: relative;
		left: -10px;
	}
	100% {
		position: relative;
		left: 0px;
	}
}

@keyframes bubble-move {
	0% {
		transform: translateY(0px) translateX(0) rotate(0);
	}
	30% {
		transform: translateY(30px) translateX(50px) rotate(15deg);
		transform-origin: center center;
	}
	50% {
		transform: translateY(50px) translateX(100px) rotate(45deg);
		transform-origin: right bottom;
	}
	80% {
		transform: translateY(30px) translateX(50px) rotate(15deg);
		transform-origin: left top;
	}
	100% {
		transform: translateY(0px) translateX(0) rotate(0);
		transform-origin: center center;
	}
}
