@use '../abstracts/colors' as c;
.home section .phone-inside {
	border: 0.5rem solid black;
	background-color: black;
	border-top: 2rem solid black;
	width: 100%;
	max-width: 16rem;
	min-width: 14rem;
	border-radius: 1.5rem;
	position: relative;
	box-shadow: rgba(0, 0, 0, 0.3) 0px 3px 15px 5px;
}
.home section .phone-inside::after {
	position: absolute;
	content: '';
	height: 8px;
	width: 8px;
	border-radius: 50px;
	background-color: rgba(255, 255, 255, 0.8);
	top: -1rem;
	left: 50%;
	z-index: 1;
}
.home section .phone-inside .screenshot {
	display: block;
}
.home section .phone-inside .screenshot {
	border: 0px;
}
/*

.screenshot {
	border: 0.3rem solid black;
	border-top: 1rem solid black;
	overflow: hidden;
	width: 100%;
	max-width: 16rem;
	min-width: 14rem;
	border-radius: 2rem;
}

/*.home section .phone-mockup {
	background-color: white;
	width: 100%;
	max-width: 16rem;
	min-width: 14rem;
	border-radius: 2rem;
	box-shadow: rgba(47, 49, 82, 0.1) 0px 0px 2rem 0px;
}
.home section .phone-inside {
	background-color: white;
	padding: 1.2rem;
	border-radius: 2rem;
	box-shadow: rgba(47, 49, 82, 0.2) 0px 0px 1.5rem 0px inset;
	position: relative;
}
*/
