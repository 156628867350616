/** @format */

.fiches {
	display: flex;
}
.fiches.mobile h1 {
	text-align: center;
	margin: 3rem 1rem;
}
.content.grow {
	animation: grow 1s ease-in-out;
}

.ficheContent ul,
.ficheContent ol,
.ficheContent p {
	font-size: 1.2rem;
}
.ficheContent p {
	margin: 1rem 0px;
	text-align: justify;
}
.ficheContent li {
	margin: 0.4rem 0px;
}
.ficheContent cite,
.ficheContent a.cite {
	color: var(--main-color-dark);
	text-decoration: none;
	font-size: 1.2rem;
	display: block;
	margin-bottom: 1rem;
}

.ficheContent a {
	color: var(--main-color-dark);
}
.ficheContent a:hover {
	opacity: 0.7;
}
.ficheContent img.fiche-img {
	border-radius: 1.5rem;
	margin: 2rem;
	float: right;
	max-width: 250px;
}

.ficheContent {
	padding: 2.5rem;
	max-width: 1040px !important;
	width: 100%;
}

.ficheContent h1 {
	color: var(--main-color);
	text-align: center;
	margin-bottom: 2rem;
}
.ficheContent .text-center {
	text-align: center;
}
.ficheContent h4,
.ficheContent button.goToFiche,
.ficheContent button.goToPrescriptionAdulte,
.ficheContent button.goToPrescriptionEnfant {
	display: none;
}
.ficheContent h2 {
	color: var(--main-color);
	text-decoration: underline;
	margin-bottom: 1rem;
	margin-top: 0.6rem;
	font-size: 1.4rem;
}
.ficheContent h3 {
	color: var(--main-color-dark);
	text-decoration: underline;
	margin: 0.6rem 0px 1rem 1rem;
	font-size: 1.2rem;
}
.ficheContent .instruments {
	color: var(--main-color);
}
#publication_date {
	font-style: italic;
}
.fiches.mobile .appStoresDiv {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

@media (max-width: 769px) {
	.fiches {
		flex-direction: column;
		flex-wrap: wrap;
		overflow-x: hidden;
		display: block;
	}
	.ficheContent {
		padding: 2.5rem 0.5rem;
	}
	#publication_date {
		margin: 1rem 2.5rem;
	}
}

@media (max-width: 1000px) {
	.ficheContent .table-category {
		flex-direction: column;
	}
	.ficheContent img.fiche-img {
		display: block;
		margin: auto;
		float: none;
	}
}

/** Old table */

.ficheContent table {
	border-collapse: collapse;
}
.ficheContent table tr:nth-of-type(odd) {
	background-color: #f9f9f9;
}
.ficheContent table,
.ficheContent table td {
	border: 1px solid #ddd;
	padding: 0.5rem;
}
.ficheContent table.infoText {
	border: none;
	width: 100%;
}
.ficheContent table.infoText td {
	text-align: left;
	vertical-align: middle;
}
.ficheContent table.infoText .icon-light {
	font-size: 15vw;
	font-size: calc(min(15vw, 50pt));
	padding: 10px;
	display: block;
}
.ficheContent table tr strong {
	white-space: nowrap;
}
.ficheContent table tr td:first-child {
	text-align: center;
}

.ficheContent table td {
	vertical-align: middle !important;
}

.ficheContent table.risques tr strong {
	white-space: nowrap;
}
.ficheContent table.risques tr td:first-child:not(.alignLeft) {
	text-align: center;
}

.ficheContent table tr .alignLeft {
	text-align: left !important;
}
.ficheContent .limited-content {
	position: relative;
}
.ficheContent .limited-content:before {
	position: absolute;
	top: 20px;
	content: '';
	width: 100%;
	height: 100%;
	background: rgba(255, 255, 250, 0)
		linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 90%) no-repeat 0
		0;
}
