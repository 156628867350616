/** @format */

@use '../abstracts/colors' as c;
.auth form {
	background-color: c.$main-color-darker;
	display: flex;
	flex-direction: column;
	max-width: 35rem;
	padding: 2rem 3rem;
	margin: auto;
	box-shadow: rgba(0, 0, 0, 0.12) 0px 0px 6px 0px;
	margin-bottom: 3rem;
}
.auth form .input {
	background-color: white;
	margin: 1rem 0rem;
	display: flex;
	font-size: 1.5rem;
	padding: 0.5rem;
	border-radius: 0.2rem;
}
.auth form .input .icon {
	display: inline-block;
	color: #b0b2be;
}
.auth form .input input {
	font-size: 1rem;
	margin-left: 1rem;
	width: 100%;
}
.auth .logo {
	align-self: center;
}
.auth form h1 {
	color: white;
	text-align: center;
	margin: 1rem 0px;
}
.auth .checkbox input {
	margin-right: 1rem;
}
.auth .checkbox {
	color: white;
	text-align: justify;
	margin: 0.5rem 0rem;
	display: flex;
	align-items: center;
	font-size: 1rem;
}
.auth p {
	color: white;
	text-align: center;
	font-size: 1rem;
}
.auth a {
	color: white;
	font-weight: bold;
}
.auth a:hover {
	opacity: 0.85;
}

.auth {
	margin-top: 2rem;
}
.auth .error {
	animation: shake 0.5s ease-in-out;
	color: white;
	background-color: c.$danger;
	padding: 0.5rem;
	border-radius: 0.5rem;
}
