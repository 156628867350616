@use '../abstracts/colors' as c;
.pricing {
	background-color: white;
	margin: 1rem;
	padding: 1.5rem;
	border-radius: 1rem;
	text-align: center;
	box-shadow: rgba(47, 49, 82, 0.1) 0px 0px 2rem 0px;
}
.pricing h3 {
	text-align: center;
	justify-content: center;
	margin-bottom: 1rem;
}
.pricing .feature h3 {
	font-size: 1.2rem !important;
}
.pricing .feature .icon {
	width: 3rem !important;
}
.pricing .toggle-switch {
	margin: 1rem;
}
.pricingDiv h2 {
	color: white;
	margin-bottom: 1rem;
}
.pricing .duration p {
	transition: color 500ms;
	display: inline-block;
	font-weight: bold;
	text-transform: uppercase;
	color: c.$text-less-important;
}
.pricing .duration p.selected {
	color: c.$main-color;
}

.pricingDiv {
	box-shadow: rgba(0, 0, 0, 0.3) 0px 7px 25px 5px;
	background-color: c.$main-color-darker;
	padding: 1rem 2rem;
	margin-bottom: 2rem !important;
	display: block !important;
}
.pricingDiv > div {
	display: flex;
	justify-content: space-between;
}

.pricing .price {
	font-size: 2rem;
	font-weight: bold;
	color: c.$main-color;
}
.pricing .price .month {
	font-size: 1rem;
	text-transform: uppercase;
}
.pricing .price-breakdown {
	margin-top: 1rem;
	font-weight: bold;
	color: grey;
}
.pricing .conditions {
	font-weight: bold;
	color: grey;
}
@media (max-width: 769px) {
	.pricingDiv > div {
		flex-direction: column;
	}
}
