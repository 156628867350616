/** @format */

@use '../abstracts/colors' as c;
.logo {
	width: 80px;
	height: 80px;
}
.logo img {
	width: 80px;
	border-radius: 1rem;
	overflow: hidden;
}

@media (max-width: 769px) {
	.logo {
		display: block;
		margin: auto;
	}
}
